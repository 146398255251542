import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MaterialUITheme } from 'rjsf-material-ui';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        width: "800px",
        justifyContent: "center",
    },
}));

const ReactJSONForm = props => {

    // https://react-jsonschema-form.readthedocs.io/en/v1.8.1/#submit-form-programmatically

    const classes = useStyles();
    const Form = withTheme(MaterialUITheme);

    return (
        <Paper className={classes.paper}>
            <Form
                schema={props.schema}
                uischema={props.uischema}
                children={props.children}
                formData={props.formData}
                onChange={({ formData }) => props.setData(formData)}
            />
        </Paper>
    )
}

export default ReactJSONForm