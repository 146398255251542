import { Button, Card, CardActions } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { useRef } from "react";
import { Radar } from "react-chartjs-2";


const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        minHeight: "610px",
        maxWidth: "576px",
        justifyContent: "center",
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
        },
    },
}));

const GraphCard = props => {

    const classes = useStyles();
    const { radar, radarOptions } = props;

    const chartRef = useRef(null);

    const saveImage = () => {
        console.log(radar)
        let ImageBase64 = chartRef.current.toBase64Image();
        const fileName = `${radar.datasets[0].label}.png`
        const downloadLink = document.createElement("a");
        downloadLink.href = ImageBase64;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <Card className={classes.card}>
            <Radar data={radar} options={radarOptions} ref={chartRef}></Radar>
            <CardActions>
                <Button size="small" color="primary" onClick={saveImage}>Download</Button>
            </CardActions>
        </Card>
    )
}

export default GraphCard