import { useContext, useEffect, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";

import { AnswerContext } from "../context/Context";
import Loading from "./Loading";
import { StepContext } from "../context/Context";
import StepContentForm from "./StepContentForm";
import StepContentConfirm from "./StepContentConfirm";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    spinner: {
        display: "flex",
        height: "550px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center"
    }
}));

const FormStepper = props => {
    const classes = useStyles();
    const { activeStep, disabled, redirect, handleNext, handleBack, handleSubmit, } = props

    const { answers, setAnswers } = useContext(AnswerContext);
    const steps = useContext(StepContext);

    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({});
    const [sdg, setSdg] = useState(0);


    useEffect(() => {
        console.log("5: rendering stepper + answers", answers);
        setLoading(true);
        let currentSdg = steps[activeStep].sdgNumber;
        setSdg(currentSdg);
        if (answers[currentSdg] === undefined) {
            console.log("5b: undefined", answers);
            console.log("5c: found;", answers[currentSdg]);
            console.log("5d: sdg = ", sdg);
            setFormData({});
        } else {
            console.log("5a: found answers", answers);
            setFormData(answers[currentSdg]);
        }
        setLoading(false);
    }, [activeStep]);

    useEffect(() => {
        console.log("setting formData");
        updateAnswers();
    }, [formData])

    const updateAnswers = () => {
        // https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component
        // https://stackoverflow.com/questions/43638938/updating-an-object-with-setstate-in-react
        console.log("6: setting answers", sdg, formData);
        setAnswers(prevAnswers => {
            let newAnswers = Object.assign({}, prevAnswers);
            console.log("6a: newAnswers", newAnswers);
            newAnswers[sdg] = formData;
            return newAnswers;
        });
    }

    return (
        <Container maxWidth="lg">
            {loading ?
                <Box className={classes.spinner}>
                    <Loading />
                </Box> :
                <>
                    <Box>
                        <Stepper activeStep={activeStep} alternativeLabel style={{ "backgroundColor": "ghostwhite" }}>
                            {steps.map((stepContent) => (
                                <Step key={stepContent.id}>
                                    <StepLabel key={stepContent.id}>{stepContent.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {steps[activeStep].lastStep ?
                            <StepContentConfirm /> :
                            <StepContentForm
                                disabled={disabled}
                                schema={steps[activeStep].dataSchema}
                                uiSchema={steps[activeStep].uiSchema}
                                children={true}
                                formData={formData}
                                setData={setFormData}
                            />
                        }
                    </Box>
                    <Box textAlign="center">
                        {steps[activeStep].firstStep ? (
                            <Button
                                onClick={redirect}
                                className={classes.backButton}
                            >
                                SDG keuzes
                            </Button>) : (
                            <Button
                                onClick={handleBack}
                                className={classes.backButton}
                            >
                                Terug
                            </Button>)}
                        {steps[activeStep].lastStep ? (
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Start
                            </Button>
                        ) : (
                            <Button variant="contained" color="primary" onClick={handleNext}>
                                Volgende
                            </Button>
                        )}
                    </Box>
                </>
            }
        </Container >
    )
}

export default FormStepper;