import { Route, Switch } from "react-router-dom"
import { useHistory } from 'react-router-dom';

import { Home as HomeIcon, Info as InfoIcon, } from '@material-ui/icons';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { createTheme } from "@material-ui/core";

import Navbar from "./ag-components/components/Navbar/Navbar";
import NotFoundPage from "./ag-components/pages/NotFoundPage";

import Contact from "./pages/Contact";
import Info from "./pages/Info";
import Home from "./pages/Home";
import Main from "./pages/Main"
import Project from "./pages/Project";
import Projects from "./pages/Projects";
import ProjectDetail from "./pages/Project-detail";
import SDG from "./pages/SDG";
import Forms from "./pages/Forms";
import { MuiThemeProvider } from "@material-ui/core";
import TokenProvider from "./auth/TokenProvider";
import { useEffect, useState } from "react";


const uidRegex = "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"

const theme = createTheme({
  palette: {
    primary: {
      main: '#006691'
    },
    secondary: {
      main: '#FE7800'
    }
  },
  typography: {
    fontFamily: [
      "MuseoSlab-500",
      "sans-serif"
    ].join(",")
  },
});

function App() {

  const [userName, setUserName] = useState("")
  let history = useHistory();

  useEffect(() => {
    const tokenProvider = TokenProvider();
    tokenProvider.getEmail().then((email) => {
      const firstName = email.split(email.includes(".") ? '.' : "@")[0]
      setUserName(firstName.charAt(0).toUpperCase() + firstName.slice(1));
    });

    if (window.location.hash) {
      history.push(window.location.pathname);
    }
  }, [])

  const links = [
    {
      id: 1,
      path: "/",
      text: "Home",
      icon: <HomeIcon />,
      content: <Home />,
    },
    {
      id: 2,
      path: "/projects",
      text: "Projecten",
      icon: <LibraryBooksIcon />,
      content: <Projects />,
    },
    {
      id: 3,
      path: "/info",
      text: "Info",
      icon: <InfoIcon />,
      content: <Info />,
    },
    {
      id: 4,
      path: "/contact",
      text: "Contact",
      icon: <ContactMailIcon />,
      content: <Contact />,
    }, {
      id: 6,
      path: `/project/:projectId(${uidRegex})`,
      content: <ProjectDetail />,
    },
    {
      id: 7,
      path: "/project/new",
      content: <Project />
    },
    {
      id: 8,
      path: `/project/:projectId(${uidRegex})/options`,
      content: <SDG />,
    },
    {
      id: 9,
      path: `/project/:projectId(${uidRegex})/sdgform`,
      content: <Forms />,
    }
  ]

  return (
    <MuiThemeProvider theme={theme}>
      <Navbar
        title={"SDG Impact Tool"}
        userName={userName}
        links={
          [
            ...links.filter(link => {
              return link.icon
            })
          ]
        }
      />
      <Switch>
        {links.map(link => (
          <Route key={link.id} exact path={link.path}>
            <Main content={link.content} />
          </Route>
        ))}
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </MuiThemeProvider>
  );
}

export default App;
