import React, { useState, useEffect } from "react"
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import { AnswerContext } from "../context/Context";
import { authFetch } from "../auth/AuthFetch";
import config from "../config";
import Loading from "../components/Loading";
import FormStepper from "../components/Stepper";
import { StepContext } from "../context/Context"
import TokenProvider from "../auth/TokenProvider";

const useStyles = makeStyles((theme) => ({
    spinner: {
        display: "flex",
        height: "550px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        color: "white",
        backgroundColor: theme.palette.primary.main
    },
    image: {
        height: "150px",
    },
    container: {
        paddingTop: theme.spacing(4),
    }
}));

const Forms = () => {
    const classes = useStyles();

    let history = useHistory();
    const { projectId } = useParams();

    const [activeStep, setActiveStep] = useState(0);
    const [answers, setAnswers] = useState({});
    const [disabled, setDisabled] = useState(false);

    const [loading, setLoading] = useState(true);
    const [steps, setSteps] = useState([]);

    const answersValue = { answers, setAnswers };

    const redirect = async () => {
        history.push(`/project/${projectId}/options`)
    }

    const postFormData = async () => {
        const url = `${config.API}/project/${projectId}/`
        const submitAnswers = await authFetch(url, {
            "method": "PATCH",
            "headers": {
                'Content-Type': 'application/json'
            },
            "body": JSON.stringify({ "answers": answers })
        });
        const response = await submitAnswers.json();
        return response;
    }

    const handleNext = () => {
        console.log("Next");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        console.log("Back");
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleSubmit = () => {
        setLoading(true);
        postFormData(answers).then((response) => {
            if (response.status === "success") {
                setLoading(false);
                history.push(`/project/${projectId}/`)
            }
        });
    }

    const getProjectData = async () => {
        const getProjectData = await authFetch(`${config.API}/project/${projectId}`, {
            "method": "GET",
        });
        const projectDataResponse = await getProjectData.json();
        return projectDataResponse.data;
    }

    const getSchemas = async (sdgNumber) => {
        try {
            const getDataSchema = await authFetch(`${config.API}/form/${sdgNumber}/schema/data/`, {
                "method": "POST",
                "headers": {
                    'Content-Type': 'application/json'
                },
                "body": JSON.stringify({ "projectId": projectId })
            });
            const dataSchemaResponse = await getDataSchema.json();

            const getUISchema = await authFetch(`${config.API}/form/${sdgNumber}/schema/ui/`, {
                "method": "POST",
                "headers": {
                    'Content-Type': 'application/json'
                },
                "body": JSON.stringify({ "projectId": projectId })
            });
            const uiSchemaResponse = await getUISchema.json();

            let name = dataSchemaResponse.data.title > 9 ? dataSchemaResponse.data.title : `0${dataSchemaResponse.data.title}`;
            let src = `https://www.sdgnederland.nl/wp-content/uploads/2018/06/SDG-icon-NL-RGB-${name}.jpg`;
            dataSchemaResponse.data.title = <img src={src} className={classes.image} alt={`sdg-${name}`} />

            return { "dataSchema": dataSchemaResponse.data, "uiSchema": uiSchemaResponse.data };
            ;
        } catch (err) {
            console.warn(err);
            return {
                "dataSchema": {},
                "uiSchema": {}
            };
        }
    }

    const getAllSteps = async (projectData) => {
        const steps = await Promise.all(projectData.sdg_numbers.map(async (s, i) => {
            const schemas = await getSchemas(s);
            return {
                id: i,
                label: `SDG ${s}`,
                sdgNumber: s,
                firstStep: i === 0 ? true : false,
                lastStep: false,
                handleNext: handleNext,
                handleBack: handleBack,
                handleSubmit: handleSubmit,
                dataSchema: schemas.dataSchema,
                uiSchema: schemas.uiSchema
            };
        }));
        steps.push({
            id: "confirm",
            label: 'Bevestig',
            sdgNumber: "bevestig",
            firstStep: false,
            lastStep: true,
            handleNext: handleNext,
            handleBack: handleBack,
            handleSubmit: handleSubmit
        })
        return steps;
    }

    useEffect(() => {
        console.log("1: getting projectData")
        getProjectData().then((projectData) => {
            const tokenProvider = TokenProvider();
            tokenProvider.getUser().then((user) => {
                if (user !== projectData.created_by) {
                    setDisabled(true);
                }
            });

            if (projectData.answers !== null) {
                console.log("2: setting previous answers: ", projectData.answers);
                setAnswers(projectData.answers);
            } else {
                const emptyAnswers = projectData.sdg_numbers.reduce((acc, val) => {
                    return { ...acc, [val]: {} };
                }, {})
                console.log("2: setting empty answers: ", emptyAnswers);
                setAnswers(emptyAnswers);
            }
            if (steps.length === 0) {
                console.log("3: getting steps");
                getAllSteps(projectData).then((steps) => {
                    console.log("4: setting steps");
                    setSteps(steps);
                    setLoading(false);
                })
            }


        })
    }, [setSteps])

    return (
        <StepContext.Provider value={steps}>
            <AnswerContext.Provider value={answersValue}>
                <Container maxWidth="lg" className={classes.container}>
                    {loading ?
                        <Box className={classes.spinner}>
                            <Loading />
                        </Box> :
                        <Box>
                            <FormStepper
                                activeStep={activeStep}
                                disabled={disabled}
                                redirect={redirect}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                handleSubmit={handleSubmit}
                            />
                        </Box>
                    }
                </Container>
            </AnswerContext.Provider>
        </StepContext.Provider>
    )
}

export default Forms;