import { v4 as uuid4 } from "uuid";
import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Card, Grid } from "@material-ui/core";

import { InfoOutlined, GpsFixed, DirectionsOutlined } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(10, 0, 6),
    },
    grid: {
        padding: theme.spacing(6, 0, 0),
    },
    gridItem: {
        display: "flex"
    },
    card: {
        minHeight: "267px",
    },
    cardheader: {
        padding: theme.spacing(1, 2, 1),
    },
    cardContent: {
        padding: theme.spacing(0, 2, 2),
    },
    icon: {
        fontSize: "xxx-large",
        padding: theme.spacing(0, 1, 1),
    }
}));

const Info = () => {

    const classes = useStyles();

    return (
        < React.Fragment >
            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" gutterBottom>
                    Info
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="div">
                    Over deze applicatie
                </Typography>
                <Grid container spacing={4} className={classes.grid}>
                    <Grid item key={uuid4()} xs={12} sm={12} md={6} className={classes.gridItem}>
                        <InfoOutlined className={classes.icon} style={{ color: "#C55A11" }} />
                        <Card className={classes.card} style={{ backgroundColor: "#FCEBE0" }}>
                            <Typography variant="h6" component="h2" className={classes.cardheader} style={{ color: "#C55A11" }}>
                                Waarom deze tool?
                            </Typography>
                            <Typography variant="body1" component="div" className={classes.cardContent}>
                                We willen onze bijdrage leveren aan een duurzame wereld en hier naar buiten toe herkenbaar en aantoonbaar aan werken. Dit kan door aansluiting bij de VN Sustainable Development Goals (SDG’s), de wereldwijde 2030 doelen voor een betere wereld. We spannen ons gericht in voor zeven SDG’s, die het dichtst bij ons liggen.

                                <br /><br />De SDG Impact Tool biedt jou inzicht in het bijdragen aan de zeven SDG’s via jouw projecten.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item key={uuid4()} xs={12} sm={12} md={6} className={classes.gridItem}>
                        <GpsFixed className={classes.icon} style={{ color: "#006691" }} />
                        <Card className={classes.card} style={{ backgroundColor: "#DEEBF7" }}>
                            <Typography variant="h6" component="h2" className={classes.cardheader}>
                                Doel
                            </Typography>
                            <Typography variant="body1" component="div" className={classes.cardContent}>
                                De tool is ontworpen om:
                                <ul>
                                    <li>aan de voorkant van jouw project inzicht te bieden in het bijdragen aan de SDG’s en aan welke knoppen je kunt draaien</li>
                                    <li>input te leveren voor jouw groene offertes (door visualisatie van SDG bijdrage)</li>
                                    <li>bij afronding de mate van impact van jouw project op de SDG’s inzichtelijk te maken</li>
                                    <li>input te leveren voor de totale SDG-rapportage van AG en het concreet maken van onze bijdrage</li>
                                </ul>
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item key={uuid4()} xs={12} sm={12} md={12} className={classes.gridItem}>
                        <DirectionsOutlined className={classes.icon} style={{ color: "#548235" }} />
                        <Card className={classes.card} style={{ backgroundColor: "#E2F0D9" }}>
                            <Typography variant="h6" component="h2" className={classes.cardheader} style={{ color: "#548235" }}>
                                Hoe werkt het?
                            </Typography>
                            <Typography variant="body1" component="div" className={classes.cardContent}>
                                De tool werkt met een vragenlijst.
                                <ul>
                                    <li>Eerst vul je kort projectinformatie in. Je geeft aan of je de vragenlijst wilt voor ingenieur (project betreft een ontwerp, constructie of realisatie van een fysiek product) of adviseur (project betreft een adviesdienst, bijv. beleid, vergunningaanvraag, beheerplan).</li>
                                    <li>Daarna selecteer je de relevante SDG’s en kun je de vragenlijst(en) invullen.</li>
                                    <li>Na beantwoording van een vragenlijst per SDG zie je waar jouw project een grote bijdrage levert en waar de bijdrage minder groot is. Op die manier krijg je inzicht in de impact van jouw project op de SDG’s en inspiratie om in de aanpak (of zelfs offertefase) van een project maatregelen op te nemen die een extra bijdrage leveren aan de SDG’s.</li>
                                </ul>
                                De SDG Impact Tool is daarmee ook een handvat voor groene offertes. (Tip: neem het resultaatoverzicht uit deze tool als afbeelding op in jouw offerte).
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment >
    )
}

export default Info