import React from "react";

const centerStyle = {
    position: "absolute",
    left: "25%",
    top: "50%",
    textAlign: "center"
}

const NotFound = () => {
    return (
        <div style={centerStyle}>
            <h2>The page you are looking for is removed or moved somewhere</h2>
        </div>
    );
};

export default NotFound;