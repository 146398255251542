import { v4 as uuid4 } from "uuid";

import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button, ButtonGroup } from "@material-ui/core";

import ContactCard from "../components/Card/ContactCard"

const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(10, 0, 6),
    },
}));

const ContactPage = props => {

    const contacts = props.contacts

    const classes = useStyles();

    return (
        < React.Fragment >
            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" gutterBottom>
                    Contact
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="p">
                    Neem contact op over deze app met deze collega's
                </Typography>
            </Container>
            <Container>
                <Grid container spacing={5} alignItems="center" justifyContent="center">
                    {contacts.map((contact) => (
                        <Grid item key={uuid4()} xs={12} sm={6} md={4}>
                            <ContactCard contact={contact} />
                        </Grid>
                    ))}
                    <Grid item key={uuid4()} xs={6} md={6}>
                        <ButtonGroup size="large" aria-label="large primary button group">
                            <Button
                                color="primary"
                                variant="contained"
                                href="https://forms.office.com/Pages/ResponsePage.aspx?id=Hmm_ieIovE2XCQIpSowb9eET6hVxRTNCpyQqQWeKbWpUMDc2SUpQOFZUNk5VVllOTU5BN1oxTEVFUCQlQCN0PWcu"
                                target="_blank"
                            >
                                Tips en vragen
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment >
    )
}

export default ContactPage