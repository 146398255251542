import { v4 as uuid4 } from "uuid";
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Grid, List, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { authFetch } from "../auth/AuthFetch";
import config from "../config";

import GraphCard from "../components/GraphCard";

import logoTransparant from "../assets/SDG_logo_horizontal_transparant.PNG"
import invuldatum from "../assets/SDG_impact_tool_icoon_invuldatum.png";
import projectnaam from "../assets/SDG_impact_tool_icoon_projectnaam.png";
import projectnummer from "../assets/SDG_impact_tool_icoon_projectnummer.png";
import projecttype from "../assets/SDG_impact_tool_icoon_projecttype.png";
import betreft from "../assets/SDG_impact_tool_icoon_betreft.png";
import projectfase from "../assets/SDG_impact_tool_icoon_projectfase.png";
import sdg from "../assets/SDG_impact_tool_icoon_SDG.png";


const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(0, 0, 6),
        '@media print': {
            display: 'block'
        },
    },
    spinner: {
        display: "flex",
        height: "550px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    card: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        minHeight: "610px",
        maxWidth: "576px",
        justifyContent: "center",
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
        },
    },
    infoCard: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
        justifyContent: "center",
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
        },
    },
    icon: {
        width: "35px",
        height: "100%",
        padding: theme.spacing(0, 2, 0, 0),
    },
    button: {
        marginTop: theme.spacing(3),
        minWidth: "150px"
    },
    parentContainer: {
        '@media print': {
            display: 'block'
        },
    },
    print: {
        '@media print': {
            display: 'block',
            pageBreakBefore: 'always',
        },
    },
}));

const ProjectDetail = () => {

    const classes = useStyles();
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [radarData, setRadarData] = useState([])
    const [summaryRadarData, setSummaryRadarData] = useState(null)

    let history = useHistory();

    const getProjectData = async () => {
        const getProjectData = await authFetch(`${config.API}/project/${projectId}`, {
            "method": "GET",
        });
        const projectDataResponse = await getProjectData.json();
        return projectDataResponse.data;
    }

    const downloadFile = async (data, name) => {
        const fileName = name ? name : "export";
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const exportProject = async () => {
        downloadFile(projectData, projectData.project_name)
    }

    const radarOptions = {
        plugins: {
            tooltip: false,
        },
        scales: {
            r: {
                ticks: {
                    display: false,
                    stepSize: 25,
                },
                min: 0,
                max: 100,
            },
        },
    };

    const getRadarData = (sdgResults) => {
        const rgb = sdgResults[1].rgb;
        const scores = Object.keys(sdgResults[1]).reduce((obj, key) => {
            if (key !== "rgb") {
                obj[key] = sdgResults[1][key]
            }
            return obj
        }, {})
        let values = Object.values(scores).map(el => {
            return ((el.score / el.max_score) * 100) > 0 ? (el.score / el.max_score) * 100 : 5
        })

        const titles = {
            6: 'Schoon water en sanitair',
            7: 'Betaalbare en duurzame energie',
            9: 'Industrie, innovatie en infrastructuur',
            11: 'Duurzame steden en gemeenschappen',
            12: 'Verantwoorde consumptie en productie',
            13: 'Klimaatactie',
            15: 'Leven op het land'
        }

        const RadarData = {
            labels: Object.keys(scores),
            datasets: [
                {
                    label: `Mate van impact op SDG ${sdgResults[0]} '${titles[sdgResults[0]]}'`,
                    backgroundColor: `rgb(${rgb}, 0.2)`,
                    borderColor: `rgb(${rgb}, 1)`,
                    pointBackgroundColor: `rgb(${rgb}, 1)`,
                    pointHoverBorderColor: `rgb(${rgb}, 1)`,
                    data: values,
                }
            ]
        };
        return RadarData;
    }

    const getAllRadarData = async (projectResults) => {
        let allRadarData = Object.entries(projectResults).map(el => {
            return getRadarData(el);
        })
        return allRadarData;
    }

    const getSummaryRadarData = async (projectResults) => {
        const sdgs = ["6", "7", "9", "11", "12", "13", "15"];
        const labels = sdgs.map(sdg => { return `SDG ${sdg}` });

        let values = sdgs.map(sdg => {
            if (Object.keys(projectResults.results).includes(sdg)) {
                let score = 0;
                let max_score = 0;
                const scores = Object.keys(projectResults.results[sdg]).reduce((obj, key) => {
                    if (key !== "rgb") {
                        obj[key] = projectResults.results[sdg][key]
                    }
                    return obj
                }, {})

                Object.values(scores).map(el => {
                    score = score + el.score
                    max_score = max_score + el.max_score
                }, {})
                return (score / max_score) * 100
            }
            // Default value of 5 %, so radar chart is always a rader, in stead of a line
            else return 5
        })

        const RadarData = {
            labels: labels,
            datasets: [
                {
                    label: "SDG's ",
                    backgroundColor: `rgb(0,102,145, 0.2)`,
                    borderColor: `rgb(0,102,145, 1)`,
                    pointBackgroundColor: `rgb(0,102,145, 1)`,
                    pointHoverBorderColor: `rgb(0,102,145, 1)`,
                    data: values,
                }
            ]
        };
        return RadarData;
    }

    const redirect = async () => {
        if (projectData.sdg_numbers) {
            history.push(`/project/${projectId}/sdgform`)
        } else {
            history.push(`/project/${projectId}/options`)
        }
    }

    useEffect(() => {
        if (loading) {
            getProjectData().then((projectData) => {
                setProjectData(projectData);
                if (projectData.results) {
                    getSummaryRadarData(projectData).then((summaryRadarData) => {
                        setSummaryRadarData(summaryRadarData);
                        getAllRadarData(projectData.results).then((radarData) => {
                            setRadarData(radarData);
                            setLoading(false);
                        })
                    })
                } else {
                    setLoading(false);
                }
            })
        }
    }, [setLoading])

    return (
        < React.Fragment >
            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" gutterBottom>
                    <img src={logoTransparant} alt="Banner" style={{ "maxWidth": "-webkit-fill-available" }}></img>
                </Typography>
                {loading ?
                    <Typography component="span" align="center" gutterBottom className={classes.spinner}>
                        <CircularProgress />
                        Loading...
                    </Typography>
                    :
                    <Container>
                        <Grid container spacing={4}>
                            <Grid item key="title" xs={12} sm={12} md={12} >
                                <Typography component="h1" variant="h2" align="center" gutterBottom>
                                    Project {projectData.project_name}
                                </Typography>
                            </Grid>
                            <Grid item key="data" xs={12} sm={12} md={6}>
                                <Typography variant="h5" color="textSecondary" component="div">
                                    <Card className={classes.card}>
                                        {/* <div>
                                        <pre dangerouslySetInnerHTML={{
                                            __html: JSON.stringify(projectData, null, 2),
                                        }} />
                                    </div> */}
                                        <List>
                                            <ListItem>
                                                <img src={projectnaam} alt="project-naam" className={classes.icon} />
                                                <ListItemText
                                                    primary="Project naam"
                                                    secondary={projectData.project_name}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <img src={projectnummer} alt="project-nummer" className={classes.icon} />
                                                <ListItemText
                                                    primary="Project nummer"
                                                    secondary={projectData.project_number}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <img src={projecttype} alt="project-type" className={classes.icon} />
                                                <ListItemText
                                                    primary="Project type"
                                                    secondary={projectData.sdg_type}
                                                />
                                            </ListItem>
                                            {projectData.project_phase ?
                                                <ListItem>
                                                    <img src={projectfase} alt="project-fase" className={classes.icon} />
                                                    <ListItemText
                                                        primary="Projectfase"
                                                        secondary={projectData.project_phase}
                                                    />
                                                </ListItem>
                                                : ""}
                                            <ListItem>
                                                <img src={sdg} alt="sdg's" className={classes.icon} />
                                                <ListItemText
                                                    primary="SDG's"
                                                    secondary={`${projectData.sdg_numbers}`}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <img src={betreft} alt="betreft" className={classes.icon} />
                                                <ListItemText
                                                    primary="Betreft"
                                                    secondary={projectData.about}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <img src={invuldatum} alt="invul-datum" className={classes.icon} />
                                                <ListItemText
                                                    primary="Invul datum"
                                                    secondary={projectData.updated}
                                                />
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Typography>
                            </Grid>
                            {summaryRadarData ?
                                <Grid item key={uuid4()} xs={12} sm={12} md={6}>
                                    <GraphCard
                                        radar={summaryRadarData}
                                        radarOptions={radarOptions}
                                    />
                                </Grid>
                                : ""}
                            {radarData.length > 0 ?
                                radarData.map((radar) => (
                                    <Grid item key={uuid4()} xs={12} sm={12} md={6}>
                                        <GraphCard
                                            radar={radar}
                                            radarOptions={radarOptions}
                                        />
                                    </Grid>
                                ))
                                : <Grid item key={uuid4()} xs={12} sm={12} md={6}>
                                    <Card className={classes.card}>
                                        <Typography variant="h5" align="center" color="textSecondary" component="div">
                                            Vul eerst de vragenlijst in
                                        </Typography>
                                        <Typography variant="h5" align="center" color="textSecondary" component="div">
                                            <Button
                                                className={classes.button}
                                                color="primary"
                                                variant="contained"
                                                onClick={redirect}
                                            >Naar de vragenlijst
                                            </Button>
                                        </Typography>
                                    </Card>
                                </Grid>}
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item key="info" xs={7} sm={7} md={10}>
                                <Card className={classes.infoCard}>
                                    <Typography variant="h6" component="h2" className={classes.cardheader} style={{ color: "#006691" }}>
                                        Ik heb mijn SDG projectresultaat, en nu?
                                    </Typography>
                                    <Typography variant="inherit" component="div" className={classes.cardContent}>
                                        <ul>
                                            <li>Bespreek de ingevulde vragenlijst en bovenstaand resultaat met je team. Op welke onderdelen (zie vragenlijst) zouden jullie de SDG bijdrage nog verder kunnen verhogen? Aan welke knoppen kunnen jullie draaien voor dit project of een vergelijkbaar toekomstig project? Tips en inspiratie voor maatregelen/oplossingen zijn o.a. te vinden in de toolbox Groene offertes.</li>
                                            <li>Neem het resultaatoverzicht als afbeelding op in je groene offerte om de klant mee te nemen in de SDG bijdrage van dit project. Dit kun je doen met de downloadknop bij de afbeelding.</li>
                                        </ul>
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item key="export-sum" xs={1} sm={1} md={1}>
                                {projectData.answers ? <Button
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                    onClick={redirect}
                                >Vragenlijst
                                </Button> : []}
                                {/* <Button
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                    onClick={exportProject}
                                >Export
                                </Button> */}
                            </Grid>
                        </Grid>
                    </Container>
                }
            </Container>
        </React.Fragment >
    )
}

export default ProjectDetail