import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTheme } from 'react-jsonschema-form';
import { Theme as MaterialUITheme } from 'rjsf-material-ui';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepContent: {
        minHeight: "600px",
        display: "flex",
        justifyContent: "center"

    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        width: "800px",
        justifyContent: "center",
    },
}));

const StepContentForm = props => {

    // https://react-jsonschema-form.readthedocs.io/en/v1.8.1/#submit-form-programmatically

    const classes = useStyles();
    const Form = withTheme(MaterialUITheme);

    return (
        <div className={classes.stepContent}>
            <Box className={classes.instructions}>
                <Paper className={classes.paper}>
                    <Form
                        disabled={props.disabled}
                        schema={props.schema}
                        uiSchema={props.uiSchema}
                        children={props.children}
                        formData={props.formData}
                        onChange={({ formData }) => props.setData(formData)}
                    />
                </Paper>
            </Box>
        </div>
    )
}

export default StepContentForm