import config from "../config"

export const getTokenUrl = () => {
    const searchParams = new URLSearchParams({
        client_id: config.CLIENT_ID,
        redirect_uri: window.location,
        scope: "login email",
        response_type: "token",
        state: Math.random().toString(36).substring(16).toString(),
    })
    return `${config.TOKEN_URL}?` + searchParams
}
