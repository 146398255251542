import TokenProvider from "./TokenProvider";

/**
 * https://www.codementor.io/@obabichev/react-token-auth-12os8txqo1
 * This function has exactly the same interface as original fetch
 * and return the same format but injects access token to each request.
 * @param {RequestInfo} input 
 * @param {RequestInfo} init 
 * @returns Promise<Response>
 */
export const authFetch = async (input, init) => {
    const tokenProvider = TokenProvider();
    const token = await tokenProvider.getToken();

    init = init || {};

    init.headers = {
        ...init.headers,
        Authorization: `Bearer ${token}`,
    };

    return fetch(input, init);
};