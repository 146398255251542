import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {
    return (
        <Box >
            <CircularProgress />
            Verwerken...
        </Box>
    )
}

export default Loading