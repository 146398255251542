import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Icon, IconButton, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, ButtonGroup, Button, Grid, Avatar } from "@material-ui/core"; // ,
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';

import { authFetch } from "../auth/AuthFetch"
import config from "../config";


const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(10, 0, 6),
    },
    histPaper: {
        minWidth: "600px",
        maxWidth: "600px",
    },
    histContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    icon: {
        color: theme.palette.primary.main,
        '&:hover': {
            transform: "scale(1.5)",
        }
    },
    iconButton: {
        // paddingRight: theme.spacing(1),
    },
    listItem: {
        marginLeft: theme.spacing(1)
    }
}));

const Projects = () => {
    const classes = useStyles();
    const [histItems, setHistItems] = useState([]);
    const [checked, setChecked] = useState([]);

    const checkedRef = useRef();
    checkedRef.current = checked;

    const getHistItems = () => {
        return authFetch(`${config.API}/project`, {
            "method": "GET",
        })
            .then(response => response.json())
            .then(response => {
                return (response.data)
            })
            .catch(err => {
                console.log(err);
                return []
            })
    }

    const exportProjects = async () => {
        const url = `${config.API}/project/export/`
        const exportProjects = await authFetch(url, {
            "method": "POST",
            "headers": {
                "Content-Type": "application/json"
            },
            "body": JSON.stringify({ "projects": checkedRef.current })
        })

        const response = await exportProjects.json()
        return response
    }

    const downloadFile = async (data) => {
        const fileName = "export";
        const json = JSON.stringify(data);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleSubmit = async () => {
        exportProjects().then((data) => {
            downloadFile(data);
        })
    }

    useEffect(() => {
        getHistItems().then((hist) => {
            setHistItems(hist);
        })
    }, [])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    return (
        < React.Fragment >
            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" gutterBottom>
                    Projecten
                </Typography>
                <Typography variant="h5" align="center" color="textSecondary" component="div">
                    Vind hier de ingevulde Projecten
                </Typography>
            </Container>
            <Grid container spacing={1} className={classes.histContainer}>
                {/* <Grid item xs={12}>
                    <ButtonGroup>
                        <Button size="small" variant="contained" color="tertiary" onClick={handleSubmit}>Exporteer projecten</Button>
                    </ButtonGroup>
                </Grid> */}
                <Grid item xs={12}>
                    <Paper className={classes.histPaper}>
                        <List className={classes.list}>
                            {histItems.map((histItem) => (
                                <ListItem key={histItem.id} className={classes.listItem}>
                                    {/* <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            value={histItem.id}
                                            checked={checked.indexOf(histItem.id) !== -1}
                                            tabIndex={-1}
                                            // disableRipple
                                            onChange={handleToggle(histItem.id)}
                                        />
                                    </ListItemIcon> */}
                                    <ListItemText
                                        primary={`${histItem.project_number} ${histItem.project_name}`}
                                        secondary={`SDG's: ${histItem.sdg_numbers}`}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton aria-label="goto" size="medium" className={classes.iconButton} href={`/project/${histItem.id}`}>
                                            <Icon className={classes.icon}>arrow_circle_right</Icon>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment >
    )
}

export default Projects