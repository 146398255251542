import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardActions, CardMedia, Typography } from "@material-ui/core";
import { Icon } from '@material-ui/core';
import logo from "../../assets/logo256.png"


const useStyles = makeStyles((theme) => ({
    cardContainer: {
        maxWidth: 345,
    },
    cardHeader: {
        // backgroundColor: "#006691",
        // color: "white"
    },
    description: {
        color: "textSecondary",
        padding: theme.spacing(0,2,2)
    },
    media: {
        height: 100,
        margin: 'auto',
        paddingTop: '56.25%', // 16:9
        padding: theme.spacing(10, 0, 6),
    },
    cardActions: {
        display: "flex",
        alignItems: "start",
        flexDirection: 'column',
        marginLeft: 0
    },
    cardIcon: {
        padding: theme.spacing(0, 1, 0),
    }
}));

const ProfileCard = props => {

    const { name, phone, email, description } = props.contact
    const profileImage = props.contact.profileImage !== null ? props.contact.profileImage : logo
    const classes = useStyles();

    return (
        <div className={classes.cardContainer}>
            <Card elevation={3}>
                <CardHeader title={name} className={classes.cardHeader}>
                </CardHeader>
                {{description}? 
                <Typography sx={{ mb: 1.5 }} className={classes.description}>
                    {description}
                </Typography>: {}}                
                <CardMedia
                    className={classes.media}
                    image={profileImage}
                    title="user"
                />
                <CardActions className={classes.cardActions}>
                    <div><Icon fontSize="small" className={classes.cardIcon}>mail</Icon> <a href={`mailto: ${email}`}>{email}</a></div>
                    <div style={{ marginLeft: 0 }}><Icon fontSize="small" className={classes.cardIcon}>call</Icon> {phone}</div>
                </CardActions>
            </Card>
        </div >
    )

}


export default ProfileCard