import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';


import logoTransparant from "../assets/SDG_logo_horizontal_transparant.PNG"
import { authFetch } from "../auth/AuthFetch";
import TokenProvider from "../auth/TokenProvider";
import ReactJSONForm from "../components/Form";
import config from "../config";


const useStyles = makeStyles((theme) => ({
    spinner: {
        display: "flex",
        height: "550px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    error: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "red",
    }
}));

const Project = () => {
    const classes = useStyles();

    let history = useHistory();

    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const [projectForm, setProjectForm] = useState(null);
    const [loading, setLoading] = useState(true);

    const userRef = useRef();
    userRef.current = user;

    const getProjectForm = async () => {
        try {
            const getDataSchema = await authFetch(`${config.API}/project/schema/data`, {
                "method": "GET",
            });
            const dataSchemaResponse = await getDataSchema.json();

            const getUISchema = await authFetch(`${config.API}/project/schema/ui`, {
                "method": "GET",
            });
            const uiSchemaResponse = await getUISchema.json();

            return (
                < ReactJSONForm
                    schema={dataSchemaResponse.data}
                    uischema={uiSchemaResponse.data}
                    children={true}
                    setData={setProjectData}
                    formData={{ "created_by": userRef.current }}
                />
            );
        } catch (err) {
            console.log(err);
            return ({})
        }
    }

    const handleSubmit = async () => {
        const url = `${config.API}/project/`;
        const getProjectId = await authFetch(url, {
            "method": "POST",
            "headers": {
                'Content-Type': 'application/json'
            },
            "body": JSON.stringify(projectData)
        });
        const response = await getProjectId.json()
        if (response.status === "success") {
            return response.data.data
        } else {
            setError(response.project_number[0])
        }
    }

    const redirect = async () => {

        handleSubmit().then((data) => {
            try {
                const projectId = data.project_id;
                history.push(`/project/${projectId}/options`)
            } catch (err) {
                console.warn(err)
            }
        })
    }

    useEffect(() => {
        const tokenProvider = TokenProvider();
        tokenProvider.getUser().then((def) => {
            setUser(def);
            getProjectForm().then((response) => {
                console.log(response);
                setProjectForm(response);
                setLoading(false);
            });
        });

    }, [setUser])

    return (
        <React.Fragment>
            <Container maxWidth="lg" component="main">
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    <img src={logoTransparant} alt="Banner" style={{ "maxWidth": "-webkit-fill-available" }}></img>
                </Typography>
                {loading ?
                    <Typography component="span" align="center" gutterBottom className={classes.spinner}>
                        <CircularProgress />
                        Loading...
                    </Typography>
                    :
                    <>
                        <Typography component="span" className={classes.error}>{error}</Typography>
                        <Typography component="span" className={classes.form}>
                            {projectForm}

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={redirect}
                            >Bevestig
                            </Button>
                        </Typography>
                    </>
                }
            </Container>
        </React.Fragment >
    )
}

export default Project