import queryString from 'query-string';

import { getTokenUrl } from '../constants/token';

/**
 * https://www.codementor.io/@obabichev/react-token-auth-12os8txqo1
 * The token provider works directly with session storage
 * It deals with getting, setting and updating the implicit bearer token
 * provider will have the next methods:

 * getToken() to get the current token and update when no token is available
 * setToken() to set token after redirect
 */
const TokenProvider = () => {
    let _token = JSON.parse(sessionStorage.getItem("token")) || null;

    const getExpirationDate = (tokenString) => {
        if (!tokenString) {
            return null;
        }
        const token = JSON.parse(atob(tokenString.split('.')[1]));

        // multiply by 1000 to convert seconds into milliseconds
        return token.exp * 1000 || null;
    }

    const decodeToken = (token) => {
        return token = JSON.parse(atob(token.split('.')[1]));
    }

    const isExpired = (exp) => {
        if (!exp) {
            return false;
        }
        return Date.now() > exp;
    };

    const updateToken = () => {

        const tokenUrl = getTokenUrl();

        if (window.location.hash === "") {
            // get token
            window.location.href = tokenUrl;
        } else {
            // set token
            let params = queryString.parse(window.location.hash);
            setToken(params.access_token);
        }
    }

    const getToken = async () => {

        // when token does not exist
        if (!_token) {
            updateToken();
        }

        // when token is expired
        if (isExpired(getExpirationDate(_token))) {
            updateToken();
        }

        return _token;
    };

    const setToken = (token) => {
        if (token) {
            sessionStorage.setItem('token', JSON.stringify(token));
        } else {
            sessionStorage.removeItem('token');
        }
        _token = token;
    };

    const getUser = async () => {
        if (!_token) {
            updateToken();
        }

        return decodeToken(_token).def
    };

    const getEmail = async () => {
        if (!_token) {
            updateToken();
        }

        return decodeToken(_token).email
    };

    return {
        getToken,
        setToken,
        getUser,
        getEmail
    };

}

export default TokenProvider


