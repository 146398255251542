import React, { useState, useEffect } from "react"
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import logoTransparant from "../assets/SDG_logo_horizontal_transparant.PNG"
import { authFetch } from "../auth/AuthFetch";
import ReactJSONForm from "../components/CustomForm";
import config from "../config";
import TokenProvider from "../auth/TokenProvider";

import "./SDG.css";

const useStyles = makeStyles((theme) => ({
    spinner: {
        display: "flex",
        height: "550px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    message: {
        color: "red",
        marginBottom: theme.spacing(1),
    }
}));


const CustomFieldTemplate = (props) => {
    const { classNames, help, errors, children } = props;

    // First object is form title
    if (children[0].props.schema.type === "object") {
        return (
            <div className={classNames}>
                {children}
                {errors}
                {help}
            </div>
        );
    }

    let title = `SDG ${children[0].props.name}`;
    let name = children[0].props.name.length === 2 ? children[0].props.name : `0${children[0].props.name}`;
    let src = `https://www.sdgnederland.nl/wp-content/uploads/2018/06/SDG-icon-NL-RGB-${name}.jpg`;

    children[0].props.schema.title = <img src={src} alt={title} height="150px" min-width="150px" class="sdg-option" />;

    return (
        <div className={classNames}>
            {children}
            {errors}
            {help}
        </div>
    );
}


// https://github.com/rjsf-team/react-jsonschema-form/issues/1810
const ObjectFieldTemplate = ({
    DescriptionField,
    description,
    TitleField,
    title,
    properties,
    required,
    uiSchema,
    idSchema,
}) => {
    const classes = useStyles();

    return (
        <>
            {(uiSchema['ui:title'] || title) && (
                <TitleField
                    id={`${idSchema.$id}-title`}
                    title={title}
                    required={required}
                />
            )}
            {description && (
                <DescriptionField
                    id={`${idSchema.$id}-description`}
                    description={description}
                />
            )}
            <Grid container={true} spacing={1} className={classes.root}>
                {properties.map((element, index) => (
                    <Grid
                        item={true}
                        xs={12} sm={6} md={3}
                        key={index}
                        style={{ marginTop: '20px', marginBottom: '0px' }}
                    >
                        {element.content}
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

const SDG = () => {

    const classes = useStyles();
    const { projectId } = useParams();

    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [sdgChoiceData, setSDGChoiceData] = useState(null);
    const [sdgChoiceForm, setSDGChoiceForm] = useState(null);

    let history = useHistory();

    const getProjectData = async () => {
        const getProjectData = await authFetch(`${config.API}/project/${projectId}`, {
            "method": "GET",
        });
        const projectDataResponse = await getProjectData.json();
        return projectDataResponse.data;
    }

    const getInitialData = async () => {
        return getProjectData().then((projectData) => {
            let formData = {}
            if (projectData.sdg_numbers) {
                projectData.sdg_numbers.forEach(number => {
                    formData[number] = true
                });
            }
            const tokenProvider = TokenProvider();
            tokenProvider.getUser().then((user) => {
                if (user !== projectData.created_by) {
                    setDisabled(true)
                }
            })
            setSDGChoiceData(formData);
            return formData

        })
    }

    const getSDGForm = async () => {
        try {
            const getDataSchema = await authFetch(`${config.API}/sdg/schema/data`, {
                "method": "GET",
            });
            const dataSchemaResponse = await getDataSchema.json();

            const getUISchema = await authFetch(`${config.API}/sdg/schema/ui`, {
                "method": "GET",
            });
            const uiSchemaResponse = await getUISchema.json();

            const formData = await getInitialData();


            return (
                < ReactJSONForm
                    disabled={disabled}
                    schema={dataSchemaResponse.data}
                    uischema={uiSchemaResponse.data}
                    children={true}
                    formData={formData}
                    FieldTemplate={CustomFieldTemplate}
                    ObjectFieldTemplate={ObjectFieldTemplate}
                    setData={setSDGChoiceData}
                />
            );
        } catch (err) {
            console.log(err);
            return ({})
        }
    }

    const handleSubmit = async () => {
        const url = `${config.API}/project/${projectId}/`
        const numbers = Object.keys(sdgChoiceData)
        const active = numbers.filter((id) => {
            return sdgChoiceData[id]
        })

        if (active.length > 0) {
            const updateSDGNumbers = await authFetch(url, {
                "method": "PATCH",
                "headers": {
                    'Content-Type': 'application/json'
                },
                "body": JSON.stringify({ "sdg_numbers": active })
            });
            const response = await updateSDGNumbers.json()
            return response
        } else {
            return {
                "status": "failed",
                "message": "Selecteer minimaal 1 SDG"
            }
        }
    }

    const redirect = async () => {
        handleSubmit().then((response) => {
            if (response.status === "success") {
                history.push(`/project/${projectId}/sdgform`)
            } else if (response.status === "failed") {
                setMessage(response.message);
            }
        })
    }

    useEffect(() => {
        getSDGForm().then((form) => {
            setSDGChoiceForm(form);
            setLoading(false);
        })
    }, [])

    return (
        <React.Fragment>
            <Container maxWidth="lg" component="main">
                <Typography component="h1" variant="h2" align="center" gutterBottom>
                    <img src={logoTransparant} alt="Banner" style={{ "maxWidth": "-webkit-fill-available" }}></img>
                </Typography>
                {loading ?
                    <Typography component="span" align="center" gutterBottom className={classes.spinner}>
                        <CircularProgress />
                        Verwerken...
                    </Typography>
                    :
                    <Typography component="span" className={classes.form}>
                        {sdgChoiceForm}
                        {message ?
                            <Grid container direction="row" alignItems="center" justifyContent="center">
                                <Typography component="div" variant="body1" className={classes.message}>{message}</Typography>
                            </Grid> : ""
                        }
                        <Box>
                            <Button
                                className={classes.backButton}
                                color="grey"
                                variant="contained"
                                href="https://anteagroup.nl/duurzaamheid/sustainable-development-goals-sdg-waar-wij-aan-werken"
                                target="_blank"
                            >
                                Meer over de SDG's
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={redirect}
                            >Bevestig
                            </Button>
                        </Box>
                    </Typography>
                }
            </Container>
        </React.Fragment>
    )

}

export default SDG