const dev = {
    root: "http://localhost:8000",
    API: "http://localhost:8000/api",
    TOKEN_URL: "https://apps.anteagroup.nl/oauth/authorize",
    CLIENT_ID: "83ec81d0cb0911e98b76dbc4aa2c88c8"
};

// const prod = {
//     root: "https://api.sdg.zolderkamer.ninja",
//     API: "https://api.sdg.zolderkamer.ninja/api",
//     TOKEN_URL: "https://zolderkamer.ninja/oauth/authorize",
//     CLIENT_ID: "bc5762a0460f11eca6ef81227e71d5b9" // zolderkamer.ninja
// };

const prod = {
    root: "https://api.sdg.apps.anteagroup.nl",
    API: "https://api.sdg.apps.anteagroup.nl/api",
    TOKEN_URL: "https://apps.anteagroup.nl/oauth/authorize",
    CLIENT_ID: "3e8c8100e0e911ec9a511f96b53cfe2a" // apps.anteagroup.nl
};

const config = process.env.NODE_ENV === 'production'
    ? prod
    : dev;

export default {
    // Add common config values here
    // MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};