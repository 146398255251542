import React from "react"
import { useHistory } from "react-router-dom"

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import hero from "../assets/hero.jpg";
import impactlogo from "../assets/SDG_impact_logo.png"

const useStyles = makeStyles((theme) => ({
    container: {
        display: "block",
        minHeight: "640px",
        height: "100vh",
        width: "100vw",
        position: "absolute",
        // backgroundColor: "grey",
        backgroundImage: `url(${hero})`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        // paddingLeft: "0 !important",
        transition: theme.transitions.create(['all'], {
            easing: theme.transitions.easing.easeInOut,
            duration: 5000,
        }),
        '&:hover': {
            transform: "scale(1.3)",
        }
    },
    pageContent: {
        position: "relative",
        paddingTop: "30vh",
        paddingLeft: "42vw",
    },
    sdgIcon: {
        padding: theme.spacing(8),
        maxWidth: "225px",
        backgroundColor: theme.palette.primary.main,
    },
    buttonGroup: {
        padding: theme.spacing(0, 0, 6),
    },
}));

const Home = () => {

    const classes = useStyles();
    let history = useHistory();

    const newProject = (e) => {
        history.push({ pathname: `/project/new` })
    }

    const goToList = (e) => {
        history.push(`/projects`)
    }

    return (
        <React.Fragment>
            <Container maxWidth={false} className={classes.container} />
            <Grid container spacing={1} className={classes.pageContent}>
                <Grid item key="logo" xs={12} sm={12} md={12}>
                    <img src={impactlogo} alt="imactLogo" className={classes.sdgIcon} />
                </Grid>
                <Grid item key="buttons" xs={12} sm={12} md={12}>
                    <ButtonGroup size="large" aria-label="large primary button group" className={classes.buttonGroup}>
                        <Button color="primary" variant="contained" key="project" onClick={e => newProject()}>Nieuw Project</Button>,
                        <Button color="primary" variant="contained" key="projects" onClick={e => goToList()}>Projectenlijst</Button>,
                    </ButtonGroup>
                </Grid>
            </Grid>
        </React.Fragment >
    )
}

export default Home